var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Tabs',{staticClass:"mb-4",attrs:{"tabs":_vm.tabs,"active-tab":_vm.activeTab},on:{"update:activeTab":function($event){_vm.activeTab=$event},"update:active-tab":function($event){_vm.activeTab=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.itemsEditable)?_c('Button',{staticClass:"mb-1 p-1",attrs:{"type":"secondary"},on:{"click":function($event){_vm.selectedCreateType = _vm.TAB_TO_CREATE_TYPE[_vm.activeTab]}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('PlusIcon'),_vm._v(" "+_vm._s(_vm.activeTab === 0 ? _vm.$t('eventMapModal.deliveryCard.productTable.tabs.actions.createDeliveredProduct') : _vm.$t('eventMapModal.deliveryCard.productTable.tabs.actions.createReturnedProduct'))+" "),_c('div',{staticClass:"mx-1"})],1)]):_vm._e()]},proxy:true}])}),_c('Table',{key:`deliveryItemsTable-${_vm.activeTab}`,staticClass:"products-table",attrs:{"data":_vm.tableData,"columns":_vm.columns,"show-index":"","border":"","rounded":"","expandable":""},scopedSlots:_vm._u([{key:"cell-sku",fn:function({ rowData: { product } }){return [_c('p',{style:({ direction: 'ltr' })},[_vm._v(_vm._s((product && product.sku) || '-'))])]}},{key:"cell-productName",fn:function({ rowData: { product } }){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('p',[_vm._v(_vm._s((product && product.name) || _vm.$t('commons.generalProduct')))])])]}},{key:"cell-quantity",fn:function({ rowData: deliveryProduct }){return [_c('div',{class:{
          warning: deliveryProduct.differences.length,
          success: deliveryProduct.differences[0] && deliveryProduct.differences[0].resolved,
        },attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(deliveryProduct.quantity)+" ")])]}},{key:"cell-firstAction",fn:function({ rowData: deliveryProduct }){return [(_vm.itemsEditable && deliveryProduct.differences.length)?_c('Button',{staticClass:"difference-button",attrs:{"type":"warning"},on:{"click":function($event){return _vm.onSolveDiff(deliveryProduct)}}},[_vm._v(" "+_vm._s(_vm.$t('commons.resolveDifference'))+" ")]):_vm._e()]}},{key:"cell-secondAction",fn:function({ rowData: deliveryProduct, rowIndex }){return [(_vm.itemsEditable)?_c('div',{staticClass:"event-modal-card-table__kebab-icon"},[(deliveryProduct.product)?_c('el-dropdown',{attrs:{"trigger":"click","placement":"bottom"},on:{"command":(action) => _vm.handleAction(action, deliveryProduct, 'main'),"visible-change":(isVisible) => _vm.actionsVisibleChange(rowIndex, isVisible)}},[_c('Button',{staticClass:"p-0 text-typography-primary",class:{ active: _vm.activeActions === rowIndex },attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();}}},[_c('KebabIcon')],1),_c('el-dropdown-menu',[_c('el-dropdown-item',{attrs:{"command":_vm.UPDATE_TYPE.QUANTITY}},[_vm._v(" "+_vm._s(_vm.$t('eventMapModal.updateProductModule.quantity'))+" ")])],1)],1):_vm._e()],1):_vm._e()]}},{key:"expandable-content",fn:function({ rowData: { items: associatedItems } }){return [_c('Table',{staticClass:"nested-table",attrs:{"data":associatedItems,"columns":_vm.nestedTableColumns,"hover":false},scopedSlots:_vm._u([{key:"cell-sku",fn:function({ rowData: { product } }){return [_vm._v(" "+_vm._s((product && product.sku) || '-')+" ")]}},{key:"cell-productName",fn:function({ rowData: { product } }){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('p',[_vm._v(_vm._s((product && product.name) || _vm.$t('commons.generalProduct')))])])]}},{key:"cell-quantity",fn:function({ rowData: associatedItem }){return [_c('div',{class:{
              warning: associatedItem.differences.length && !associatedItem.differences[0].resolved,
              success: associatedItem.differences.length && associatedItem.differences[0].resolved,
            },attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(associatedItem.quantity)+" ")])]}},{key:"cell-firstAction",fn:function({ rowData: associatedItem }){return [(_vm.itemsEditable && associatedItem.differences.length)?_c('Button',{staticClass:"difference-button",attrs:{"type":"warning"},on:{"click":function($event){return _vm.onSolveDiff(associatedItem)}}},[_vm._v(" "+_vm._s(_vm.$t('commons.resolveDifference'))+" ")]):_vm._e()]}},{key:"cell-secondAction",fn:function({ rowData: associatedItem, rowIndex }){return [_c('div',{staticClass:"event-modal-card-table__kebab-icon"},[(_vm.itemsEditable && associatedItem.product)?_c('el-dropdown',{attrs:{"trigger":"click","placement":"bottom"},on:{"command":(action) => _vm.handleAction(action, associatedItem, 'associated'),"visible-change":(isVisible) => _vm.actionsVisibleChange(rowIndex, isVisible)}},[_c('Button',{staticClass:"p-0 text-typography-primary",class:{ active: _vm.activeActions === rowIndex },attrs:{"type":"text"},on:{"click":function($event){$event.stopPropagation();}}},[_c('KebabIcon')],1),_c('el-dropdown-menu',[_c('el-dropdown-item',{attrs:{"command":_vm.UPDATE_TYPE.QUANTITY}},[_vm._v(_vm._s(_vm.$t('eventMapModal.updateProductModule.quantity')))])],1)],1):_vm._e()],1)]}}],null,true)})]}}])}),(_vm.selectedCreateType)?_c('AddDeliveryItemModal',{attrs:{"products":_vm.currentSupplierProducts,"type":_vm.selectedCreateType},on:{"close":function($event){_vm.selectedCreateType = null},"item-added":_vm.addDelivery}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }