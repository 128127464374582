<template>
  <div>
    <Tabs :tabs="tabs" :active-tab.sync="activeTab" class="mb-4">
      <template #actions>
        <Button
          v-if="itemsEditable"
          type="secondary"
          class="mb-1 p-1"
          @click="selectedCreateType = TAB_TO_CREATE_TYPE[activeTab]"
        >
          <div class="d-flex align-items-center">
            <PlusIcon />
            {{
              activeTab === 0
                ? $t('eventMapModal.deliveryCard.productTable.tabs.actions.createDeliveredProduct')
                : $t('eventMapModal.deliveryCard.productTable.tabs.actions.createReturnedProduct')
            }}
            <div class="mx-1" />
          </div>
        </Button>
      </template>
    </Tabs>
    <Table
      :key="`deliveryItemsTable-${activeTab}`"
      :data="tableData"
      :columns="columns"
      class="products-table"
      show-index
      border
      rounded
      expandable
    >
      <template #cell-sku="{ rowData: { product } }">
        <p :style="{ direction: 'ltr' }">{{ (product && product.sku) || '-' }}</p>
      </template>
      <template #cell-productName="{ rowData: { product } }">
        <div class="d-flex align-items-center">
          <p>{{ (product && product.name) || $t('commons.generalProduct') }}</p>
        </div>
      </template>
      <template #cell-quantity="{ rowData: deliveryProduct }">
        <div
          :class="{
            warning: deliveryProduct.differences.length,
            success: deliveryProduct.differences[0] && deliveryProduct.differences[0].resolved,
          }"
          dir="ltr"
        >
          {{ deliveryProduct.quantity }}
        </div>
      </template>
      <template #cell-firstAction="{ rowData: deliveryProduct }">
        <Button
          v-if="itemsEditable && deliveryProduct.differences.length"
          type="warning"
          class="difference-button"
          @click="onSolveDiff(deliveryProduct)"
        >
          {{ $t('commons.resolveDifference') }}
        </Button>
      </template>
      <template #cell-secondAction="{ rowData: deliveryProduct, rowIndex }">
        <div v-if="itemsEditable" class="event-modal-card-table__kebab-icon">
          <el-dropdown
            v-if="deliveryProduct.product"
            trigger="click"
            placement="bottom"
            @command="(action) => handleAction(action, deliveryProduct, 'main')"
            @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          >
            <Button
              type="text"
              class="p-0 text-typography-primary"
              :class="{ active: activeActions === rowIndex }"
              @click.stop
            >
              <KebabIcon />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item :command="UPDATE_TYPE.QUANTITY">
                {{ $t('eventMapModal.updateProductModule.quantity') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
      <template #expandable-content="{ rowData: { items: associatedItems } }">
        <Table :data="associatedItems" :columns="nestedTableColumns" :hover="false" class="nested-table">
          <template #cell-sku="{ rowData: { product } }">
            {{ (product && product.sku) || '-' }}
          </template>
          <template #cell-productName="{ rowData: { product } }">
            <div class="d-flex align-items-center">
              <p>{{ (product && product.name) || $t('commons.generalProduct') }}</p>
            </div>
          </template>
          <template #cell-quantity="{ rowData: associatedItem }">
            <div
              :class="{
                warning: associatedItem.differences.length && !associatedItem.differences[0].resolved,
                success: associatedItem.differences.length && associatedItem.differences[0].resolved,
              }"
              dir="ltr"
            >
              {{ associatedItem.quantity }}
            </div>
          </template>
          <template #cell-firstAction="{ rowData: associatedItem }">
            <Button
              v-if="itemsEditable && associatedItem.differences.length"
              type="warning"
              class="difference-button"
              @click="onSolveDiff(associatedItem)"
            >
              {{ $t('commons.resolveDifference') }}
            </Button>
          </template>
          <template #cell-secondAction="{ rowData: associatedItem, rowIndex }">
            <div class="event-modal-card-table__kebab-icon">
              <el-dropdown
                v-if="itemsEditable && associatedItem.product"
                trigger="click"
                placement="bottom"
                @command="(action) => handleAction(action, associatedItem, 'associated')"
                @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
              >
                <Button
                  type="text"
                  class="p-0 text-typography-primary"
                  :class="{ active: activeActions === rowIndex }"
                  @click.stop
                >
                  <KebabIcon />
                </Button>
                <el-dropdown-menu>
                  <el-dropdown-item :command="UPDATE_TYPE.QUANTITY">{{
                    $t('eventMapModal.updateProductModule.quantity')
                  }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </Table>
      </template>
    </Table>
    <AddDeliveryItemModal
      v-if="selectedCreateType"
      :products="currentSupplierProducts"
      :type="selectedCreateType"
      @close="selectedCreateType = null"
      @item-added="addDelivery"
    />
  </div>
</template>

<script>
import { isNil, omit } from 'ramda';
import { computed, ref, getCurrentInstance } from 'vue';

import { Tabs, Button, Table } from '@/modules/core';
import { KebabIcon, PlusIcon } from '@/assets/icons';
import { useDeliveryItemCreate, AddDeliveryItemModal } from '@/modules/delivery';

import { UPDATE_TYPE } from '../../../commons/components/UpdateProductModal.vue';

const TABLE_HEADERS = {
  SKU: 'sku',
  PRODUCT_NAME: 'productName',
  QUANTITY: 'quantity',
  FIRST_ACTION: 'firstAction',
  SECOND_ACTION: 'secondAction',
};

export default {
  name: 'ProductsTable',
  components: { Tabs, Table, Button, PlusIcon, KebabIcon, AddDeliveryItemModal },
  props: {
    items: { type: Array, default: () => [] },
    supplierProducts: { type: Array, default: () => [] },
    deliveryId: { type: String, required: true },
    itemsEditable: { type: Boolean, default: true },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const activeTab = ref(0);

    const delivered = computed(() =>
      props.items
        .filter((i) => (i.supplierValue ? i.supplierValue >= 0 : i.quantity >= 0))
        .map((i) => ({ ...i, expandable: i.items?.length, expandableCustomClass: 'p-0' }))
    );
    const returned = computed(() =>
      props.items
        .filter((p) => (p.supplierValue ? p.supplierValue < 0 : p.quantity < 0))
        .map((p) => ({
          ...p,
          items: p.items?.map((item) => ({
            ...item,
            quantity: Math.abs(item.quantity),
          })),
          quantity: Math.abs(p.quantity),
          expandable: p.items?.length,
          expandableCustomClass: 'p-0',
        }))
    );

    const activeActions = ref();
    const actionsVisibleChange = (index, isVisible) => (activeActions.value = isVisible ? index : null);

    const handleAction = (action, deliveryProduct, type) => {
      if (Object.values(UPDATE_TYPE).indexOf(action) !== -1) {
        let value = '-';
        switch (action) {
          case UPDATE_TYPE.QUANTITY:
            value = deliveryProduct[action].toLocaleString(root.$i18n.locale) ?? value;
            break;
          default:
        }
        emit('update', {
          id: deliveryProduct.id,
          product: deliveryProduct.product,
          items: deliveryProduct.items,
          quantity: deliveryProduct.quantity,
          value,
          updateType: action,
          type,
          activeTab: activeTab.value,
        });
      }
    };
    const { deliveryItemCreate } = useDeliveryItemCreate();

    return {
      tableData: computed(() => {
        switch (activeTab.value) {
          case 0:
            return delivered.value;
          case 1:
            return returned.value;
          default:
            return [];
        }
      }),
      tabs: computed(() => [
        {
          text: root.$i18n.t('eventMapModal.deliveryCard.productTable.tabs.deliveredProducts'),
          badgeValue: delivered.value.length,
        },
        {
          text: root.$i18n.t('eventMapModal.deliveryCard.productTable.tabs.returnedProducts'),
          badgeValue: returned.value.length,
        },
      ]),
      activeTab,
      TAB_TO_CREATE_TYPE: {
        0: 'delivered',
        1: 'returned',
      },
      addModalVisible: ref(false),
      activeActions,
      actionsVisibleChange,
      handleAction,
      UPDATE_TYPE,
      isNil,
      selectedCreateType: ref(null),
      currentSupplierProducts: computed(() =>
        activeTab.value === 0
          ? props.supplierProducts.filter(({ id }) => delivered.value.every(({ product }) => id !== product.id))
          : props.supplierProducts.filter(({ id }) => returned.value.every(({ product }) => id !== product.id))
      ),
      delivered,
      returned,
      deliveryItemCreate,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('commons.sku'),
          key: TABLE_HEADERS.SKU,
          width: '10%',
        },
        {
          header: this.$t('commons.product'),
          key: TABLE_HEADERS.PRODUCT_NAME,
          width: '20%',
        },
        {
          header: this.$t('commons.quantity'),
          key: TABLE_HEADERS.QUANTITY,
        },
        {
          key: TABLE_HEADERS.FIRST_ACTION,
          customClass: 'p-0 align-middle flex-start',
          width: '90px',
        },
        {
          key: TABLE_HEADERS.SECOND_ACTION,
          width: '50px',
        },
      ];
    },
    nestedTableColumns() {
      return [{ key: 'empty', width: '110px' }, ...this.columns].map(omit(['header', 'sortCallback']));
    },
  },
  methods: {
    onSolveDiff(deliveryItem) {
      this.$emit('solve-diff', deliveryItem);
    },
    async addDelivery(data) {
      const loading = this.$loading();
      if (this.activeTab === 1) {
        data.quantity = data.quantity * -1;
      }
      try {
        const product = this.currentSupplierProducts.find(({ id }) => id === data.productId);

        const newDeliveryItem = {
          quantity: data.quantity,
          productId: data.productId,
          items: product.associatedProducts.map((item) => {
            const updatedAssociatedItemQuantity = data.quantity * item.quantity;
            return {
              quantity: updatedAssociatedItemQuantity,
              productId: item.productId,
            };
          }),
        };
        await this.deliveryItemCreate({
          deliveryId: this.deliveryId,
          data: newDeliveryItem,
        });
        this.$emit('refetch');
        this.selectedCreateType = null;
        this.$message.success(this.$t('commons.messages.action.success'));
      } catch (error) {
        this.$message.error(this.$t('commons.messages.action.error'));
      } finally {
        loading.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../commons/style.scss';
.warning {
  color: $warning;
}

.success {
  color: $success;
}
.products-table {
  tr {
    .difference-button {
      visibility: hidden;
    }

    &:hover .difference-button {
      visibility: visible;
    }
  }
}

::v-deep .nested-table {
  th {
    padding: 0;
  }
  td {
    background: $secondary;
    &.bg-compare {
      background: #e1e7f9;
    }
  }
}
</style>
