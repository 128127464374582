<template>
  <DifferenceModal
    event-type="delivery"
    :customer-order-item="deliveryProduct"
    :difference="difference"
    :diffs-to-show="[DIFFERENCE_PROPERTY.QUANTITY]"
    :document="document"
    :supplier="delivery.supplier"
    :business="business"
    :date="new Date(delivery.date)"
    :supplier-order-item="orderItem"
    @close="$emit('close')"
    @apply="handleQuantityDifferenceResolution"
  >
    <div class="buttons-container">
      <span class="header-button">
        <div class="header fw-bold">{{ tenantName }}</div>
        <el-select
          v-model="selectedQuantityResolutionOption"
          disabled
          class="mt-2 mb-2 w-100"
          :placeholder="$t('eventMapModal.deliveryCard.differenceModal.decision.quantity.takeDecision')"
        />
      </span>
    </div>
  </DifferenceModal>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { flatten } from 'ramda';

import DifferenceModal, { DIFFERENCE_PROPERTY } from '../../../commons/components/differenceModal';

export default {
  components: { DifferenceModal },
  props: {
    deliveryItemId: { type: String, required: true },
    delivery: { type: Object, required: true },
    business: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;

    const deliveryProduct = computed(() => {
      const associatedItems = flatten(
        props.delivery.products
          .filter((deliveryItem) => deliveryItem.items?.length)
          .map((deliveryItem) => deliveryItem.items)
      );
      return [...props.delivery.products, ...associatedItems].find(
        (deliveryItem) =>
          deliveryItem.id === props.deliveryItemId || deliveryItem.differenceItemId === props.deliveryItemId
      );
    });

    const selectedQuantityResolutionOption = ref(null);

    return {
      DIFFERENCE_PROPERTY,
      tenantName: computed(() => props.business?.name),
      difference: { quantity: deliveryProduct.value.differences[0] },
      orderItem: computed(() => ({ quantity: deliveryProduct.value.differences[0].supplierValue })),
      deliveryProduct,
      document: computed(() => props.delivery.source?.document),
      selectedQuantityResolutionOption,
      async handleQuantityDifferenceResolution() {
        if (!selectedQuantityResolutionOption.value) {
          emit('close');
          return;
        }

        const loading = root.$loading();
        try {
          emit('refetch');
          root.$message.success(root.$i18n.t('commons.messages.action.success'));
          emit('close');
        } catch (e) {
          console.error(e); //TODO: add to logger when UI will have one
          root.$message.error(root.$i18n.t('commons.messages.action.error'));
        } finally {
          loading.close();
        }
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.buttons-container {
  margin-top: 2em;
  display: flex;
  align-items: center;

  .header-button {
    display: flex;
    flex-direction: column;

    [dir='rtl'] & {
      align-items: flex-start;
    }
    [dir='ltr'] & {
      align-items: flex-end;
    }
    width: 100%;

    .header {
      font-size: 0.87em;
      color: black;
      margin-bottom: 0.6em;
    }

    span {
      width: 100%;
      display: flex;
      align-items: center;

      @at-root [dir='rtl'] i {
        margin-right: 0.7em;
      }

      @at-root [dir='ltr'] i {
        margin-left: 0.7em;
      }
    }

    .el-button {
      height: 2.75em;
      width: 100%;

      &[disabled] {
        background-color: #ecf0f3;
        color: #c4c6cf;
        border-color: #ecf0f3;
      }
    }
  }

  .divider {
    margin: 0 1em;
    height: 9em;
  }
}
</style>
